import LIFEINSURANCE from "./Assets/icons/lifeInsurance.png";
import HEALTHINSURANCE from "./Assets/icons/healthInsurance.png";
import HOMEINSURANCE from "./Assets/icons/homeInsurance.png";
import VEHICALINSURANCE from "./Assets/icons/vehicalInsurance.png";
import BUSINESSINSURANCE from "./Assets/icons/businessInsurance.png";
import PROPERTYINSURANCE from "./Assets/icons/propertyInsurance.png";

export const PROJECTS = [
  {
    image: LIFEINSURANCE,
    name: "Life Insurance",
    description:
      "Secure your family's future with life insurance, providing funds for outstanding debts and living expenses while offering a financial cushion during difficult times.",
  },
  {
    image: HEALTHINSURANCE,
    name: "Health Insurance",
    description:
      "Health insurance helps cover medical expenses, reducing out-of-pocket costs for doctor visits, hospital stays, surgeries, and prescriptions.",
  },
  {
    image: HOMEINSURANCE,
    name: "Home Insurance",
    description:
      "Home insurance provides coverage for damages to your home and personal belongings due to perils like fire, theft, vandalism, and natural disasters, safeguarding your financial investment.",
  },
  {
    image: VEHICALINSURANCE,
    name: "Vehicle Insurance",
    description:
      "Vehicle insurance helps cover the costs of repairs or replacement if your vehicle is damaged or stolen, protecting your financial investment.",
  },
  {
    image: BUSINESSINSURANCE,
    name: "Business Insurance",
    description:
      "It provides liability protection for lawsuits arising from injuries, property damage, or negligence claims against your business, ensuring you are covered for legal costs and settlements.",
  },
  {
    image: PROPERTYINSURANCE,
    name: "Property Insurance",
    description:
      "Having property insurance gives you peace of mind, knowing that you are financially protected against unexpected events that could result in significant loss.",
  },
];

export const SKILLS = [
  { name: "ReactJs", initialRating: 4 },
  { name: "React-Native", initialRating: 4 },
  { name: "JavaScript", initialRating: 4 },
  { name: "Redux", initialRating: 4 },
  { name: "Shopify/Reactjs", initialRating: 4 },
  { name: "Nodejs", initialRating: 3 },
  { name: "Koajs", initialRating: 3 },
  { name: "Html", initialRating: 4 },
  { name: "CSS", initialRating: 4 },
  { name: "Unit-Testing(Jest)", initialRating: 3 },
  { name: "TypeScript", initialRating: 4 },
  { name: "Graphql", initialRating: 4 },
  { name: "Apollo", initialRating: 4 },
  { name: "AntD | ReactStrap | Material UI", initialRating: 4 },
  { name: "Shopify Polaris", initialRating: 4 },
  { name: "Git", initialRating: 4 },
];

export const TOOLS = [
  "Visual Studio Code",
  "Git",
  "Chrome DevTools",
  "Npm (Node Package Manager)",
  "Atom",
  "Sublime Text",
  "Linux",
  "Heroku",
  "Postman",
];
