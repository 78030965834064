import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/rushi.JPG";
import SocialMedia from "../SocialMedia";
import TypeWriter from "./TypeWriter";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={6} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'M
                <strong className="main-name"> Rushi Shah</strong>
              </h1>

              <p className="heading-description blockquote">
                I’m an Insurance and Investment Agent offering personalized
                solutions to secure your financial future. Let’s create a plan
                that protects your assets and grows your wealth.
              </p>

              <div className="heading-type">
                <TypeWriter />
              </div>
            </Col>

            <Col md={5}>
              <img src={myImg} className="profile-pic" alt="avatar" />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
