import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            With 18 years of experience as an Insurance Advisor, I’m committed
            to delivering exceptional, up-to-date advice tailored to each
            client’s needs. My dedication to continuous learning through
            industry seminars and self-study ensures that I stay informed about
            the latest trends and solutions in insurance and investment.
            Alongside advising clients, I mentor my team to uphold high
            standards of service. This has helped us efficiently manage a large
            portfolio of policies while providing top-notch service to our
            policyholders.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
