import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import { PROJECTS } from "../../Constants";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          We Provide <strong className="purple">professional </strong>
          <b />
          Insurance Services
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {PROJECTS.map((project, index) => (
            <ProjectCard
              imgPath={project.image}
              technologyUsed={project.technologyUsed}
              isBlog={false}
              title={project.name}
              description={project.description}
              link={project.url}
            />
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
