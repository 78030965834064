import React from "react";
import Home from "../Home/Home";
import About from "../About/About";
import Projects from "../Projects/Projects";
import Resume from "../Resume/ResumeNew";

function Main({ homeRef, aboutRef, projectsRef, resumeRef }) {
  return (
    <section>
      <div ref={homeRef}>
        <Home />
      </div>
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={projectsRef}>
        <Projects />
      </div>
      <div ref={resumeRef}>
        <Resume />
      </div>
    </section>
  );
}

export default Main;
